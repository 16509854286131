import { template as template_e01ba244d840439386f12bf1f0da1ebb } from "@ember/template-compiler";
const FKControlMenuContainer = template_e01ba244d840439386f12bf1f0da1ebb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
